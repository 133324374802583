import React, { useCallback, useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'semantic-ui-react';

import Header from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import { ButtonAccent, ButtonTransparentAccent } from 'components/ui/button';
import { Checkbox } from 'components/ui/inputs/Checkbox';
import { SelectBox } from 'components/ui/inputs/Dropdown';
import { LimitedTextInput, TextInput } from 'components/ui/inputs/TextInput';

import * as svars from 'assets/style/variables';

export const formElementTypeMapping = {
  InputFormElement: 'Input element',
  MultipleChoiceFormElement: 'Multichoice selector',
};

const typesOptions = [
  {
    key: 'InputFormElement',
    value: 'InputFormElement',
    text: t`input-field`,
  },
  {
    key: 'MultipleChoiceFormElement',
    value: 'MultipleChoiceFormElement',
    text: t`multi-choice-field`,
  },
];

function FormItemManagementModal({
  formNames,
  create,
  formElement,
  open,
  onClose,
  tagSetsOptions,
  onAddElement,
  onUpdateElement,
  addItemLabel,
  editItemLabel,
  mandatoryIsAllowed,
  mutableIsAllowed,
}) {
  const [label, setLabel] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [formType, setFormType] = useState('');
  const [tag_set, setTagSet] = useState('');
  const [dropdown, setDropdown] = useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [mutable, setMutable] = useState(false);
  useEffect(() => {
    if (formElement) {
      setLabel(formElement.label);
      setPlaceholder(formElement.placeholder);
      setFormType(formElement.form_type);
      if (formElement.form_type === 'MultipleChoiceFormElement') {
        setTagSet(formElement.tag_set);
        setDropdown(formElement.dropdown);
      }
      if (mandatoryIsAllowed) setMandatory(formElement.mandatory);
      if (mutableIsAllowed) setMutable(formElement.mutable);
    }
  }, [formElement]);

  const onSubmit = useCallback(async () => {
    const element = {
      form_type: formType,
      ...formElement,
      label,
      placeholder,
      mandatory,
      mutable,
    };
    if (formType === 'MultipleChoiceFormElement') {
      element.tag_set = tag_set;
      element.dropdown = dropdown;
    }
    if (create) {
      await onAddElement(element);
    } else {
      onUpdateElement(element);
    }
    onClose();
    // if (create) emptyValues();
  }, [
    create,
    dropdown,
    formElement,
    formType,
    label,
    mandatory,
    mutable,
    onAddElement,
    onClose,
    onUpdateElement,
    placeholder,
    tag_set,
  ]);
  const nameIsAlreadyUsed =
    formNames.includes(label) && (!formElement || formElement.label !== label);
  const isFormIncomplete =
    !formType ||
    !label ||
    (formType === 'MultipleChoiceFormElement' && !tag_set) ||
    nameIsAlreadyUsed;
  const placeholderRequired = formType === 'InputFormElement' || dropdown;
  return (
    <Modal closeIcon open={open} onClose={onClose}>
      <Modal.Header content={create ? addItemLabel : editItemLabel} />
      <Modal.Content>
        <Segment style={{ display: 'inline-flex', width: '100%' }}>
          <span style={{ width: '50%', margin: `0 ${svars.spaceLarge}` }}>
            <Header
              content={<Trans id="form-item-name" />}
              style={{
                marginTop: svars.spaceMedium,
                marginBottom: svars.spaceNormal,
              }}
            />
            <LimitedTextInput
              style={{ width: '100%' }}
              value={label}
              onChange={(event) => {
                setLabel(event.target.value);
              }}
              maxCharacters={40}
            />

            {nameIsAlreadyUsed && (
              <span style={{ color: svars.colorDanger }}>
                <Icon name="exclamation" />
                <Trans id="form-item-name-already-used" />
              </span>
            )}
          </span>
          <span style={{ width: '50%', margin: `0 ${svars.spaceLarge}` }}>
            <Header
              content={t`placeholder`}
              maxCharacters={40}
              style={{
                marginTop: svars.spaceMedium,
                marginBottom: svars.spaceNormal,
              }}
            />
            <TextInput
              style={{ width: '100%' }}
              value={placeholder}
              disabled={!placeholderRequired}
              onChange={(event) => {
                setPlaceholder(event.target.value);
              }}
            />
          </span>
        </Segment>
        <Segment style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ width: '50%', margin: `0 ${svars.spaceLarge}` }}>
            <Header
              style={{
                marginTop: svars.spaceMedium,
                marginBottom: svars.spaceNormal,
              }}
            >
              <Trans id="form-item-type" />
            </Header>
            {create ? (
              <SelectBox
                options={typesOptions}
                value={formType}
                placeholder={t`select-a-type-of-form-field`}
                onChange={(e, data) => setFormType(data.value)}
                withI18n
              />
            ) : (
              <span style={{ marginLeft: '3px' }}>
                {formElementTypeMapping[formType]}
              </span>
            )}
          </span>
          {formType === 'MultipleChoiceFormElement' && (
            <span
              style={{
                maxWidth: svars.inputMaxWidth,
                margin: `0 ${svars.spaceLarge}`,
              }}
            >
              <Header
                content={t`tag-set`}
                style={{
                  marginTop: svars.spaceMedium,
                  marginBottom: svars.spaceNormal,
                }}
              />
              <SelectBox
                options={tagSetsOptions}
                value={tag_set.id}
                onChange={(e, data) => {
                  const choice = data.options.filter(
                    (option) => option.value === data.value
                  )[0];
                  setTagSet({ id: choice?.key, name: choice?.text });
                }}
              />
              <Header
                content={t`dropdown`}
                style={{
                  marginTop: svars.spaceMedium,
                  marginBottom: svars.spaceNormal,
                }}
              />
              <Checkbox
                checked={dropdown}
                onChange={(e, data) => {
                  setDropdown(data.checked);
                }}
              />
            </span>
          )}
        </Segment>

        {mandatoryIsAllowed ? (
          <Segment>
            <Checkbox
              style={{ width: '100%' }}
              toggle
              label={t`mandatory`}
              checked={mandatory}
              onChange={(e, data) => {
                setMandatory(data.checked);
              }}
            />
          </Segment>
        ) : null}
        {mutableIsAllowed ? (
          <Segment>
            <Checkbox
              style={{ width: '100%' }}
              toggle
              label={t`field-is-mutable`}
              checked={mutable}
              onChange={(e, data) => {
                setMutable(data.checked);
              }}
            />
          </Segment>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentAccent
          type="submit"
          onClick={onClose}
          content={t`cancel`}
        />
        <ButtonAccent
          type="submit"
          disabled={isFormIncomplete}
          onClick={onSubmit}
        >
          {create ? t`create` : t`save-changes`}
        </ButtonAccent>
      </Modal.Actions>
    </Modal>
  );
}

FormItemManagementModal.propTypes = {
  formNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tagSetsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  create: PropTypes.bool,
  formElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.number,
    form_type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    tag_set: PropTypes.objectOf(PropTypes.string),
    dropdown: PropTypes.bool,
    mandatory: PropTypes.bool,
    mutable: PropTypes.bool,
  }),
  onUpdateElement: PropTypes.func,
  onAddElement: PropTypes.func,
  addItemLabel: PropTypes.string.isRequired,
  editItemLabel: PropTypes.string.isRequired,
  // Whether the form element can be flagged as mandatory
  mandatoryIsAllowed: PropTypes.bool.isRequired,
  // Whether the form element can be flagged as mutable
  mutableIsAllowed: PropTypes.bool.isRequired,
};

FormItemManagementModal.defaultProps = {
  create: false,
  onUpdateElement: null,
  onAddElement: null,
  formElement: null,
};

export default FormItemManagementModal;
