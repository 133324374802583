import React from 'react';

import { range } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const widthPointerTransition = 'width 0.5s ease-in-out';

const Pointer = styled.div`
  ${({ danger, width, height, light }) => {
    const arrowSideHeight = height / 2;
    // Set the width of the arrow head equal to the height of the shape
    const arrowWidth = arrowSideHeight;
    const backgroundColor =
      (danger && ((light && svars.colorDangerLighter) || svars.colorDanger)) ||
      (light && svars.accentColorMedium) ||
      svars.accentColor;
    return `
        width: ${width}px;
        height: ${height}px;
        position: relative;
        background: ${backgroundColor};
        margin-right: ${arrowWidth + 2}px;
        transition: ${widthPointerTransition};
        
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: ${arrowWidth}px solid white;
          border-top: ${arrowSideHeight}px solid transparent;
          border-bottom: ${arrowSideHeight}px solid transparent;
        }
        &:before {
          content: '';
          position: absolute;
          right: -${arrowWidth}px;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: ${arrowWidth}px solid ${backgroundColor};
          border-top: ${arrowSideHeight}px solid transparent;
          border-bottom: ${arrowSideHeight}px solid transparent;
        }`;
  }}
`;

Pointer.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Pointer;

const dotPointerWidth = 18;

export function DottedPointer({ danger, width, height, light }) {
  const nPointer = width / dotPointerWidth;
  return (
    <span
      style={{
        width: `${width}px`,
        height: `${height}px`,
        display: 'inline-flex',
        transition: widthPointerTransition,
      }}
    >
      {range(nPointer).map(() => (
        <Pointer
          danger={danger}
          light={light}
          height={height}
          width={dotPointerWidth}
        />
      ))}
    </span>
  );
}
DottedPointer.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  light: PropTypes.bool,
  danger: PropTypes.bool,
};

DottedPointer.defaultProps = { light: null, danger: false };
