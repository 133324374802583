import React, { useCallback, useMemo, useRef } from 'react';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import {
  CartesianGrid,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';

import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';
import RechartsTooltip from 'components/ui/visualization/RechartsTooltip';
import { ScatterChart } from 'components/ui/visualization/StyledChart';

import config from 'config';
import { getInterpolatedColor } from 'utils/colors';
import commonPropTypes from 'utils/commonPropTypes';
import { floatFormatter, numberFormatter } from 'utils/formatter';
import { capitalize } from 'utils/helpers';
import { svgWhiteOutlineDef } from 'utils/svg';

import * as svars from 'assets/style/variables';

import ScatterChartLabel from './ScatterChartLabel';
import computeScatterLabels from './tools';

const renderCustomizedLabel = (rectangles, setPosition, scatterProps) =>
  function CustomizedLabelComponent(props) {
    return scatterProps && scatterProps.top ? (
      <ScatterChartLabel
        setPosition={setPosition}
        rectangles={rectangles}
        top={scatterProps.top}
        bottom={scatterProps.bottom + scatterProps.height}
        left={scatterProps.left}
        right={scatterProps.left + scatterProps.width}
        {...props}
      />
    ) : null;
  };

const getFieldKeys = (reducedTooltip, categoryDataKey, radiusTooltipFieldKey) =>
  reducedTooltip
    ? [categoryDataKey]
    : [
        categoryDataKey,
        'n_chunks',
        'average_sentiment',
        ...(radiusTooltipFieldKey ? [radiusTooltipFieldKey] : []),
      ];

const scatterShapeFactory = ({
  fillOpacity,
  getOpacity,
  getRadius,
  onShapeClick,
  radius,
}) => {
  function ScatterShape({ cx, cy, color, displayed, ...item }) {
    const extraProps = {};
    if (onShapeClick) {
      extraProps.onClick = (event) => onShapeClick(event, item);
      extraProps.style = { cursor: 'pointer' };
    }
    if (displayed === false) return null;
    return (
      <circle
        stroke="white"
        strokeWidth={1}
        fill={color}
        fillOpacity={getOpacity?.(item) || fillOpacity}
        cx={cx}
        cy={cy}
        r={(getRadius && getRadius(item)) || radius}
        {...extraProps}
      />
    );
  }
  ScatterShape.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    color: PropTypes.string,
    displayed: PropTypes.bool,
  };

  ScatterShape.defaultProps = {
    cx: null,
    cy: null,
    color: null,
    displayed: false,
  };

  return ScatterShape;
};

function SentimentVolumeScatterChart({
  categoryDataKey,
  chartId,
  colorFormatter,
  useSentimentColor,
  data,
  fillOpacity,
  getOpacity,
  getRadius,
  height,
  isAnimationActive,
  labelFormatter,
  labelKey,
  legendAlign,
  legendLayout,
  legendVerticalAlign,
  legendWrapperStyles,
  loading,
  onShapeClick,
  radius,
  radiusTooltipFieldKey,
  reducedTooltip,
  scatterLabels,
  setScatterLabels,
  shape,
  withLegend,
  withTooltip,
}) {
  const scatterRef = useRef();
  const { scatterData, yAxisWidth } = useMemo(() => {
    const formatted = (data || []).map((item) => ({
      ...item,
      label: labelFormatter(item[categoryDataKey], {
        ontologyName: item.ontology_name,
      }),
      color:
        (useSentimentColor && getInterpolatedColor(item.average_sentiment)) ||
        (colorFormatter && colorFormatter(item)),
    }));

    const yAxisNMaxChars = Math.max(
      ...formatted.map(({ n_chunks }) => `${Math.floor(n_chunks * 1.3)}`.length)
    );
    if (getRadius) {
      // Sort by radius so largest radius are drawn last and overlap smaller ones
      formatted.sort((a, b) => getRadius(a) - getRadius(b));
    }

    return {
      scatterData: formatted,
      yAxisWidth: Math.max(20, (yAxisNMaxChars - 1) * 9),
    };
  }, [data]);

  const setPosition = useCallback(
    (index, x, y) => {
      scatterLabels[index].position.position = { x, y };
      setScatterLabels(scatterLabels);
    },
    [scatterLabels, setScatterLabels]
  );

  const getLabelText = useCallback(
    (item) =>
      labelFormatter(item[labelKey || categoryDataKey], {
        ontologyName: item ? item.ontology_name : null,
      }),
    [labelFormatter, labelKey, categoryDataKey]
  );
  const makeScatterLabels = useCallback(
    (element) => {
      scatterRef.current = element;
      if (
        element &&
        setScatterLabels &&
        scatterLabels &&
        (!scatterLabels.length ||
          (element?.props?.points &&
            element?.props?.points?.length !== scatterLabels?.length))
      ) {
        computeScatterLabels(element.props, getLabelText, setScatterLabels);
      }
    },
    [scatterLabels]
  );
  const renderShape = scatterShapeFactory({
    radius,
    getRadius,
    fillOpacity,
    getOpacity,
    onShapeClick,
  });

  if (loading) return <LoadingDataVisualization height={height} />;
  return (
    <ResponsiveContainer width="100%" height={height} id={chartId}>
      {data ? (
        (data.length && (
          <ScatterChart margin={svars.getChartMargins(true)}>
            {svgWhiteOutlineDef}
            <CartesianGrid stroke={svars.cartesianGridStrokeColor} />
            <Scatter
              isAnimationActive={isAnimationActive}
              data={scatterData}
              ref={makeScatterLabels}
              shape={shape || renderShape}
            >
              {(scatterLabels && scatterRef?.current?.props && (
                <LabelList
                  style={{ pointerEvents: 'none' }}
                  content={renderCustomizedLabel(
                    scatterLabels,
                    setPosition,
                    scatterRef?.current?.props || {}
                  )}
                  dataKey="label"
                  position="top"
                />
              )) ||
                null}
            </Scatter>
            <XAxis
              type="number"
              dataKey="average_sentiment"
              name="average_sentiment"
              label={{
                value: capitalize(t`sentiment`),
                offset: 0,
                position: 'bottom',
              }}
              domain={config.SENTIMENT_DOMAIN}
              tickFormatter={floatFormatter}
              axisLine={{ stroke: svars.chartFontColor }}
              tickLine={{ stroke: svars.chartFontColor }}
            />
            <YAxis
              width={yAxisWidth}
              type="number"
              dataKey="n_chunks"
              name="n_chunks"
              label={{
                value: capitalize(t`volume`),
                angle: 0,
                offset: 15,
                position: 'top',
                style: { textAnchor: 'start' },
              }}
              padding={{ bottom: 0, top: 0 }}
              tickFormatter={numberFormatter}
              axisLine={{ stroke: svars.chartFontColor }}
              tickLine={{ stroke: svars.chartFontColor }}
            />

            <ZAxis dataKey={categoryDataKey} name={capitalize(t`category`)} />
            {radiusTooltipFieldKey ? (
              <ZAxis
                dataKey={radiusTooltipFieldKey}
                name={capitalize(t`contribution`)}
                zAxisId={1}
              />
            ) : null}
            {withLegend ? (
              <Legend
                wrapperStyle={legendWrapperStyles}
                layout={legendLayout}
                align={legendAlign}
                verticalAlign={legendVerticalAlign}
                payload={scatterData.map(
                  ({ product_hierarchy_group, color, ...item }) => ({
                    color,
                    value: labelFormatter(product_hierarchy_group, {
                      ontologyName: item ? item.ontology_name : undefined,
                    }),
                    type: 'circle',
                  })
                )}
              />
            ) : null}
            {withTooltip ? (
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                content={
                  <RechartsTooltip
                    data={data}
                    fieldKeys={getFieldKeys(
                      reducedTooltip,
                      categoryDataKey,
                      radiusTooltipFieldKey
                    )}
                    entityLabelFormatter={() => (item, meta) =>
                      labelFormatter(item, {
                        ontologyName: meta ? meta.ontology_name : undefined,
                      })}
                  />
                }
              />
            ) : null}

            <ReferenceLine
              x={0}
              stroke="red"
              strokeWidth={2}
              strokeOpacity={0.15}
            />
          </ScatterChart>
        )) || <EmptyDataVisualization />
      ) : (
        <div />
      )}
    </ResponsiveContainer>
  );
}

const scatterLabelsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    checked: PropTypes.bool,
    id: PropTypes.string,
    key: PropTypes.string,
    label: PropTypes.string,
    position: PropTypes.shape({
      labelHeight: PropTypes.number,
      labelWidth: PropTypes.number,
      position: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    }),
    value: PropTypes.string,
  })
);

SentimentVolumeScatterChart.propTypes = {
  // All non highlighted data objects
  data: PropTypes.arrayOf(commonPropTypes.kpiPropTypes),
  // The categorical data key to use. If not provided, fallback on method `getCategoryDataKey`
  categoryDataKey: PropTypes.string,
  labelFormatter: PropTypes.func.isRequired,
  // A method to get a color from an entity - used if provided
  colorFormatter: PropTypes.func,
  // If set to true, wille use sentiment value to associate a color to each dot
  useSentimentColor: PropTypes.bool,
  height: PropTypes.number,
  // Customize scatter shape
  shape: PropTypes.func,
  // Set a click action on shape - expects a double arrow function receiving shape item as props,
  // then click event
  // NB : this applies only if `shape` is not provided
  onShapeClick: PropTypes.func,
  // Is chart animation active
  isAnimationActive: PropTypes.bool,
  // Default radius used to draw scatter elements
  radius: PropTypes.number,
  // A method to compute radius based on the data item to draw. Overrides `radius` when provided.
  getRadius: PropTypes.func,
  // A key of an optional tooltip field to display radius related indicator
  // Field metadata should be present in file RechartTooltip.jsx
  radiusTooltipFieldKey: PropTypes.string,
  // Whether to display legend
  withLegend: PropTypes.bool,
  withTooltip: PropTypes.bool,
  // Show only label in tooltip
  reducedTooltip: PropTypes.bool,
  legendLayout: PropTypes.string,
  legendAlign: PropTypes.string,
  legendVerticalAlign: PropTypes.string,
  legendWrapperStyles: PropTypes.shape({
    paddingLeft: PropTypes.string,
    maxWidth: PropTypes.string,
  }),
  fillOpacity: PropTypes.number,
  // A method to get opacity based on the data item to draw. Overrides `fillOpacity` when provided.
  getOpacity: PropTypes.func,
  loading: PropTypes.bool,
  // Id to set to chart if provided (used for export button)
  chartId: PropTypes.string,
  labelKey: PropTypes.string,
  // Scatter points labelling - labels are controlled so parent component can manage / store theme
  scatterLabels: scatterLabelsPropTypes,
  setScatterLabels: PropTypes.func,
};

SentimentVolumeScatterChart.defaultProps = {
  data: null,
  height: 300,
  categoryDataKey: undefined,
  colorFormatter: undefined,
  useSentimentColor: false,
  shape: undefined,
  onShapeClick: undefined,
  isAnimationActive: true,
  radius: 12,
  getRadius: null,
  radiusTooltipFieldKey: null,
  withLegend: false,
  withTooltip: true,
  reducedTooltip: false,
  legendLayout: 'vertical',
  legendAlign: 'right',
  legendVerticalAlign: 'middle',
  legendWrapperStyles: undefined,
  fillOpacity: 1,
  getOpacity: null,
  loading: false,
  chartId: undefined,
  labelKey: undefined,
  scatterLabels: undefined,
  setScatterLabels: null,
};

export default SentimentVolumeScatterChart;
