import { t } from '@lingui/macro';

export const analysisCreateDescription = [
  t`help-message.analysis-create-description.1`,
  t`help-message.analysis-create-description.2`,
];

export const analysisUpdateDescription = [
  t`help-message.analysis-update-description`,
];

export const analysisBaseProductHierarchyGroupDescription = [
  t`help-message.base-product-hierarchy-group-description.1`,
  t`help-message.base-product-hierarchy-group-description.2`,
];

export const analysisSourceGroupDescription = [
  t`help-message.source-group-description`,
];

export const analysisComparativeProductHierarchyGroupDescription = [
  t`help-message.comparative-product-hierarchy-group-description.1`,
  t`help-message.comparative-product-hierarchy-group-description.2`,
  t`help-message.comparative-product-hierarchy-group-description.3`,
  t`help-message.comparative-product-hierarchy-group-description.4`,
  t`help-message.comparative-product-hierarchy-group-description.5`,
];

export const volumeHelpMessage = t`help-message.volume-description`;
export const averageSentimentHelpMessage = t`help-message.average-sentiment-description`;

export const differentialSentimentHelpMessage = t`help-message.benchmark-differential-sentiment-description`;

export const competitiveCumulatedVolumeHelpMessage = t`help-message.competitive-cumulated-volume-description`;

export const categoryHelpMessage = t`help-message.selected-category-description`;
