import React, { useCallback, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import copyToClipboard from 'copy-to-clipboard';

import * as svars from 'assets/style/variables';

const IconStyled = styled(Icon)`
  &&& {
    margin: ${svars.spaceXSmall} ${svars.spaceNormal};
    color: ${(props) =>
      props.clicked ? svars.colorSuccessHover : svars.colorPrimary};
    font-size: ${svars.fontSizeXLarge};
  }
`;

const StyledText = styled.span``;

const timeoutLength = 1700;

const CopyableContainer = styled.div`
  display: flex;
  width: 100%;
  color: inherit;

  &:hover {
    cursor: pointer;
    color: ${svars.accentColorHover};

    & ${IconStyled} {
      color: ${svars.accentColor};
      animation-duration: 500ms;
      animation-name: pulse;
    }
  }
`;

function CopyToClipboard({
  trigger,
  text,
  confirmedCopyMessage,
  iconName,
  children,
  style,
}) {
  const [isCopied, setIsCopied] = useState(false);
  const clickTimeout = useRef();
  const clearClickTimeout = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
  };
  const copyText = useCallback(
    (e) => {
      e.stopPropagation();
      clearClickTimeout();
      copyToClipboard(typeof text === 'function' ? text() : text);
      setIsCopied(true);
      // eslint-disable-next-line react/no-unused-class-component-methods
      clickTimeout.current = setTimeout(() => {
        setIsCopied(false);
      }, timeoutLength);
    },
    [text]
  );

  return (
    <Popup
      trigger={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span onClick={copyText} style={style}>
          {trigger || (
            <CopyableContainer>
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  margin: 'auto 0',
                }}
              >
                {children}
              </span>
              <IconStyled name={iconName} clicked={isCopied ? 1 : 0} />
            </CopyableContainer>
          )}
        </span>
      }
      open={isCopied}
      content={<StyledText>{confirmedCopyMessage}</StyledText>}
      on="click"
      position="top left"
    />
  );
}

CopyToClipboard.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  confirmedCopyMessage: PropTypes.string,
  iconName: PropTypes.string,
  children: PropTypes.node,
  trigger: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.objectOf(PropTypes.any),
};

CopyToClipboard.defaultProps = {
  confirmedCopyMessage: 'Copié',
  iconName: 'copy',
  children: null,
  trigger: null,
  style: {},
};

export default CopyToClipboard;
