import React, { useCallback, useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import BeatingLoader from 'components/ui/BeatingLoader';
import EmptyDataPage from 'components/ui/EmptyDataPage';
import Segment from 'components/ui/Segment';
import { AnalyticsAwareCheckbox } from 'components/ui/inputs/Checkbox';
import { ResettableTextInput } from 'components/ui/inputs/TextInput';
import emptyDataUrl from 'components/ui/svg/undraw_empty_xct9.svg';

import commonPropTypes from 'utils/commonPropTypes';
import { accentInsensitiveSearch } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import HeaderFactory from './header';

const sortItems = (items, { key, ascending }, accessor, sortType) =>
  items.sort((element1, element2) => {
    const element1Value = accessor ? accessor(element1) : element1[key];
    const element2Value = accessor ? accessor(element2) : element2[key];
    // If value is boolean do not consider `ascending` to have true value always first
    if (element1Value === true) return -1;
    if (element1Value === false) return 0;
    let sortValue = 0;
    if (element1Value !== null) {
      if (sortType === 'basic') {
        sortValue = element2Value - element1Value;
      } else if (element1Value.localeCompare) {
        sortValue = element1Value.localeCompare(element2Value);
      }
    }
    return sortValue * (ascending ? 1 : -1);
  });

const ActionBar = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;
const MaybeClickableSegment = styled(Segment)`
  &&& {
    margin-right: ${svars.spaceNormal};

    ${({ clickable }) =>
      clickable
        ? css`
            cursor: pointer;
            &:hover {
              ${svars.hoverClickableCss}
            }
            ${svars.activeClickableCss}
          `
        : 'background: none'}
  }
`;

function ManagementList({
  items,
  displayFavoritesOnly,
  onToggleDisplayFavouritesOnly,
  defaultSorted,
  renderItemRow,
  textFilterPlaceholder,
  actions,
  loading,
  emptyListHeader,
  EmptyListContent,
  onRowClick,
  rowFields,
  nActions,
  testid,
}) {
  const [columnSorted, setColumnSorted] = useState(defaultSorted);
  const [facetSearchValue, setFacetSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const onResetAllFilters = useCallback(() => {
    setFacetSearchValue('');
  }, [items, columnSorted]);
  const onItemSort = useCallback(
    (fieldName) => () => {
      const newFacetSorted = {
        key: fieldName,
        ascending:
          columnSorted.key !== fieldName ||
          (columnSorted.key === fieldName && !columnSorted.ascending),
      };
      setColumnSorted(newFacetSorted);
    },
    [items, columnSorted, filteredItems]
  );
  const onUpdateSearchResults = useCallback(
    (searchValue) => {
      const sortField = rowFields.find(({ id }) => id === columnSorted?.key);
      if (items && items.length) {
        const baseItems = displayFavoritesOnly
          ? items.filter(({ favorite }) => favorite)
          : [...items];
        setFilteredItems(
          sortItems(
            searchValue
              ? accentInsensitiveSearch(
                  baseItems,
                  searchValue,
                  ({ name }) => name
                )
              : baseItems,
            columnSorted,
            sortField?.accessor,
            sortField?.sortType
          )
        );
      }
    },
    [displayFavoritesOnly, columnSorted, items]
  );
  const debouncedFacetSearchValue = useDebounce(facetSearchValue);
  const onSearchChange = useCallback((e, { value }) => {
    setFacetSearchValue(value);
  }, []);
  const onResetSearch = useCallback(() => {
    setFacetSearchValue('');
  }, []);

  // Update search results when one of sorted or text query changes
  useEffect(() => {
    onUpdateSearchResults(debouncedFacetSearchValue);
  }, [debouncedFacetSearchValue, columnSorted, onUpdateSearchResults]);
  const HeaderComponent = ManagementList.HeaderFactory([
    ...rowFields,
    { key: 'actions', width: 55 * nActions, auto: 'true' },
    { key: 'menu', width: 10 },
  ]);
  return (
    <>
      <ActionBar>
        <span style={{ flexGrow: 1, maxWidth: '50%' }}>
          <ResettableTextInput
            placeholder={textFilterPlaceholder}
            onChange={onSearchChange}
            onReset={onResetSearch}
            value={facetSearchValue}
            disabled={items?.length === 0}
            baseIconName="search"
            large={1}
            fluid
            data-testid={testid}
          />
        </span>
        {onToggleDisplayFavouritesOnly ? (
          <AnalyticsAwareCheckbox
            gaCategory="View Facet List"
            gaAction="Display favorites"
            style={{
              fontSize: svars.fontSizeLarge,
              padding: `0 ${svars.spaceMediumLarge}`,
            }}
            onClick={onToggleDisplayFavouritesOnly}
            label={t`display-favorites-only`}
            checked={displayFavoritesOnly}
          />
        ) : null}
        {actions}
      </ActionBar>
      {(loading && <BeatingLoader />) ||
        (!filteredItems?.length && (
          <EmptyDataPage
            headerText={emptyListHeader}
            illustrationUrl={emptyDataUrl}
            actionComponent={
              <EmptyListContent resetFilters={onResetAllFilters} />
            }
          />
        )) || (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto hidden',
              alignItems: 'baseline',
            }}
          >
            <HeaderComponent onSort={onItemSort} sorted={columnSorted} />
            <div
              style={{
                height: '100%',
                // Make sure the list grows even when scrollable horizontally
                minWidth: '100%',
                overflow: 'clip auto',
              }}
            >
              {filteredItems.map((item) => (
                <MaybeClickableSegment
                  clickable={onRowClick ? '1' : ''}
                  key={`facet-${item.id}`}
                  onClick={onRowClick ? () => onRowClick(item) : null}
                  data-testid={`bo-list-card-${item.name}`}
                >
                  {renderItemRow({ fields: rowFields, item })}
                </MaybeClickableSegment>
              ))}
            </div>
          </div>
        )}
    </>
  );
}

ManagementList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  displayFavoritesOnly: PropTypes.bool,
  onToggleDisplayFavouritesOnly: PropTypes.func,
  defaultSorted: PropTypes.shape({
    key: PropTypes.string,
    ascending: PropTypes.bool,
  }).isRequired,
  renderItemRow: PropTypes.func.isRequired,
  rowFields: PropTypes.arrayOf(commonPropTypes.uiFields).isRequired,
  textFilterPlaceholder: PropTypes.string.isRequired,
  actions: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  // Header text displayed if list is empty
  emptyListHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  // Content displayed below Header text if list is empty
  EmptyListContent: PropTypes.oneOfType([PropTypes.func]),
  // List becomes clickable when providing this prop
  onRowClick: PropTypes.func,
  // Number of icon actions in each row - used to compute header cells widths
  nActions: PropTypes.number,
  testid: PropTypes.string,
};
ManagementList.defaultProps = {
  displayFavoritesOnly: false,
  emptyListHeader: null,
  EmptyListContent: () => null,
  items: undefined,
  onToggleDisplayFavouritesOnly: null,
  loading: false,
  onRowClick: null,
  nActions: 0,
  testid: undefined,
};
ManagementList.HeaderFactory = HeaderFactory;

export default ManagementList;
