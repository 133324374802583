import { Header as BaseHeader } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const makeHeader = (color, fontSize, fontFamily, fontWeight) => styled(
  BaseHeader
)`
  &&& {
    &:first-letter {
      text-transform: uppercase;
    }
    color: ${color};
    font-size: ${fontSize};
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    ${({ nomargin }) => (nomargin ? 'margin: 0;' : '')};
    display: ${({ inline }) => (inline ? 'inline' : 'block')};
  }
`;

const SmallHeader = makeHeader(
  svars.fontColorLighter,
  svars.fontSizeSmaller,
  svars.fontFamilyTitle,
  svars.fontWeightMedium
);

const Header = makeHeader(
  'inherit',
  svars.fontSizeMedium,
  svars.fontFamilyTitle,
  svars.fontWeightMedium
);

const LightHeader = makeHeader(
  svars.fontColorBase,
  svars.fontSizeMedium,
  svars.fontFamilyTitle,
  svars.fontWeightBase
);

export const LightLargeHeader = makeHeader(
  svars.fontColorBase,
  svars.fontSizeLarge,
  svars.fontFamilyTitle,
  svars.fontWeightLight
);

const LargeHeader = makeHeader(
  svars.fontColorBase,
  svars.fontSizeBig,
  svars.fontFamilyTitle,
  svars.fontWeightSemiBold
);

SmallHeader.Content = BaseHeader.Content;
Header.Content = BaseHeader.Content;
LargeHeader.Content = BaseHeader.Content;

export default Header;
export { SmallHeader, LargeHeader, LightHeader };
