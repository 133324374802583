import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { t } from '@lingui/macro';

import AxiosInterceptorNavigate from 'actions/middlewares';

import ErrorBoundary from 'components/ErrorBoundary';
import AdminHomePage from 'components/admin/AdminHomePage';
import ComponentDesignPage from 'components/admin/ComponentDesignPage';
import IntentionalErrorPage from 'components/admin/IntentionalErrorPage';
import LivePage from 'components/admin/LivePage';
import AdminOperationsPage from 'components/admin/operations/AdminOperationsPage';
import AdminSupportPage from 'components/admin/support/AdminSupportPage';
import ValidationPage from 'components/admin/validation/ValidationPage';
import CustomerSupportPage from 'components/customer/CustomerSupportPage';
import SupportModal from 'components/customer/SupportModal';
import ViewFacetAnalysisPage from 'components/customer/analysis/ViewFacetAnalysisPage';
import CampaignHomePage from 'components/customer/campaign/CampaignHomePage';
import CampaignListPage from 'components/customer/campaign/CampaignListPage';
import CampaignLoader from 'components/customer/campaign/CampaignLoader';
import { CampaignPagesCrumb } from 'components/customer/campaign/CampaignPagesCrumb';
import CreateCampaignPage from 'components/customer/campaign/CreateCampaignPage';
import AdministationAnnotationFormTabPage from 'components/customer/campaign/administrate/AnnotationFormTabPage';
import CampaignAdministrationPage from 'components/customer/campaign/administrate/CampaignAdministrationPage';
import AdministationUrlFormTabPage from 'components/customer/campaign/administrate/UrlFormTabPage';
import CampaignChannelListPage from 'components/customer/campaign/channel/CampaignChannelListPage';
import CustomizeCampaignPage from 'components/customer/campaign/customize/CustomizeCampaignPage';
import MonitoringDashboardLayout from 'components/customer/campaign/monitor/MonitoringDashboardLayout';
import MonitoringDashboardTab from 'components/customer/campaign/monitor/MonitoringDashboardTab';
import MonitoringSearchTab from 'components/customer/campaign/monitor/MonitoringSearchTab';
import ProductHierarchyGroupListPage from 'components/customer/home/ProductHierarchyGroupListPage';
import ViewFacetListPage from 'components/customer/home/ViewFacetListPage';
import ViewFacetCreatePage from 'components/customer/home/view-facet-create/ViewFacetCreatePage';
import LoginLayout from 'components/login/LoginLayout';
import LoginPage from 'components/login/LoginPage';
import RenewPasswordPage from 'components/login/RenewPasswordPage';
import UpdatePasswordPage from 'components/login/UpdatePasswordPage';
import LoggedLayout, { AdminLoggedLayout } from 'components/ui/layout/Layout';
import {
  CampaignListLoader,
  FacetListLoader,
} from 'components/ui/layout/loaders';
import {
  CampaignCrumb,
  FacetCrumb,
  pageCrumbFactory,
} from 'components/ui/navigation/PageBreadcrumbs';

import { PageTracker } from 'utils/hooks';
import { AdminOnlyRoute, RedirectToHome } from 'utils/routing';

const baseRoutes = () => (
  <Route
    errorElement={<ErrorBoundary />}
    element={
      <>
        <AxiosInterceptorNavigate />
        <ReduxToastr
          timeOut={2000}
          newestOnTop
          // preventDuplicates looks broken at the moment
          // https://github.com/diegoddox/react-redux-toastr/issues/185
          // preventDuplicates
          position="bottom-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          closeOnToastrClick
        />
        <SupportModal />
        <PageTracker />
        <Outlet />
      </>
    }
  >
    <Route element={<LoginLayout />}>
      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/renew-password" element={<RenewPasswordPage />} />
      <Route exact path="/update-password" element={<UpdatePasswordPage />} />
    </Route>
    <Route path="/admin" handle={{ crumb: pageCrumbFactory(t`admin`) }}>
      <Route
        index
        element={
          <AdminLoggedLayout
            withV3Navbar
            // withSidebar
            fullHeight
            // withLargeSidebar
            // withBreadcrumbs
          >
            <AdminHomePage />
          </AdminLoggedLayout>
        }
      />
      <Route
        element={
          <AdminLoggedLayout withV3Navbar>
            <Outlet />
          </AdminLoggedLayout>
        }
      >
        <Route
          exact
          path="intentional-error"
          element={<IntentionalErrorPage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t`validation`) }}
          path="validation"
          element={<ValidationPage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t`support`) }}
          path="support"
          element={<AdminSupportPage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t`operations`) }}
          path="operations"
          element={<AdminOperationsPage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t`live`) }}
          path="live"
          element={<LivePage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t`design`) }}
          path="design"
          element={<ComponentDesignPage />}
        />
      </Route>
    </Route>
    <Route
      exact
      path="/product-groups"
      element={
        <LoggedLayout withV3Navbar fullHeight>
          <ProductHierarchyGroupListPage />
        </LoggedLayout>
      }
      handle={{ crumb: pageCrumbFactory(t`product-groups`) }}
    />
    <Route
      path="/facets"
      handle={{ crumb: pageCrumbFactory(t`analyses`) }}
      element={
        <LoggedLayout withV3Navbar fullHeight>
          <FacetListLoader />
          <Outlet />
        </LoggedLayout>
      }
    >
      <Route index element={<ViewFacetListPage />} />
      <Route
        exact
        path="create"
        element={<ViewFacetCreatePage />}
        handle={{ crumb: pageCrumbFactory(t`create-analysis`) }}
      />
      <Route
        exact
        path="update/:viewFacetId"
        element={<ViewFacetCreatePage />}
        handle={{ crumb: pageCrumbFactory(t`update-analysis`) }}
      />
      <Route
        exact
        path="copy/:viewFacetId"
        element={<ViewFacetCreatePage />}
        handle={{ crumb: pageCrumbFactory(t`copy-analysis`) }}
      />
      <Route
        path="analyze/:viewFacetId/:dashboardPane"
        element={<ViewFacetAnalysisPage />}
        handle={{ crumb: FacetCrumb }}
      />
    </Route>
    <Route
      exact
      path="/campaign"
      handle={{ crumb: pageCrumbFactory(t`campaigns`) }}
      element={
        <LoggedLayout fullHeight withV3Navbar>
          <CampaignListLoader />
          <Outlet />
        </LoggedLayout>
      }
    >
      <Route index element={<CampaignListPage />} />
      <Route
        path="create"
        element={
          <AdminOnlyRoute>
            <CreateCampaignPage />
          </AdminOnlyRoute>
        }
        handle={{ crumb: pageCrumbFactory(t`create-new-campaign`) }}
      />
      <Route
        exact
        path=":campaignId"
        element={
          <CampaignLoader>
            <Outlet />
          </CampaignLoader>
        }
        handle={{ crumb: CampaignCrumb }}
      >
        <Route index element={<CampaignHomePage />} />
        <Route handle={{ crumb: CampaignPagesCrumb }} element={<Outlet />}>
          <Route
            index
            path="customize"
            element={
              <AdminOnlyRoute>
                <CustomizeCampaignPage />
              </AdminOnlyRoute>
            }
            //  ={{ crumb: pageCrumbFactory(t`campaign-customization-cta`) }}
          />
          <Route
            index
            path="channels"
            element={<CampaignChannelListPage />}
            //  ={{ crumb: CampaignPagesCrumb }}
          />
          <Route
            path="administrate"
            element={
              <AdminOnlyRoute>
                <CampaignAdministrationPage />
              </AdminOnlyRoute>
            }
            //  ={{ crumb: pageCrumbFactory(t`administrate`) }}
          >
            <Route path="url-form" element={<AdministationUrlFormTabPage />} />
            <Route
              path="annotation-form"
              element={<AdministationAnnotationFormTabPage />}
            />
            <Route
              index
              path="*"
              element={<Navigate to="url-form" replace />}
            />
          </Route>
          <Route
            path="monitor"
            element={<MonitoringDashboardLayout />}
            // handle={{ crumb: pageCrumbFactory(t`monitor`) }}
          >
            <Route path="dashboard" element={<MonitoringDashboardTab />} />
            <Route path="search" element={<MonitoringSearchTab />} />
            <Route
              index
              path="*"
              element={<Navigate to="dashboard" replace />}
            />
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="/contact-support" element={<CustomerSupportPage />} />
    <Route path="*" element={<RedirectToHome />} />
  </Route>
);

export default baseRoutes;
