import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { LANGUAGES as languages } from 'reducers/locale';

import { switchLanguage } from 'actions/locale';
import { toggleSupportModal } from 'actions/ui';

import Link, { RouterLinkV2 } from 'components/ui/Link';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';
import logoWithFontImageSrc from 'components/ui/svg/logo-with-font.svg';

import config from 'config';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const FooterContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  padding: ${svars.spaceNormal} ${svars.spaceMedium};
`;
const Container = styled.div`
  display: flex;
  height: 100%;
`;

const LeftColumn = styled.div`
  &&&&& {
    height: 100%;
    width: 50%;
    background: ${svars.secondaryGradient};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${svars.colorWhite};
    align-self: flex-end;
    white-space: pre-line;
    text-align: end;
    padding: ${svars.spaceLarge};
    padding-bottom: ${svars.spaceXLarge};
  }
`;

export const LeftMessageContainer = styled.div`
  align-self: flex-end;
  text-align: end;
  max-width: 550px;
  font-size: ${svars.fontSizeBig};
  line-height: ${svars.lineHeightSizeBig};
  font-weight: ${svars.fontWeightLight};
`;
const RightColumn = styled.div`
  &&&& {
    height: 100%;
    width: 50%;
    padding: ${svars.spaceLarge};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

const FormContainer = styled.div`
  max-width: 380px;
  width: 100%;
`;

const LogoImage = styled.img`
  &&& {
    z-index: 2;
    width: 100%;
    max-height: 100px;
  }
`;

export const LoginHeaderMessageContainer = styled.div`
  text-align: center;
  font-size: ${svars.fontSizeXLarge};
  line-height: ${svars.lineHeightSizeXLarge};
  font-weight: ${svars.fontWeightLight};
  color: ${svars.colorGrey};
  padding: ${svars.spaceMediumLarge} 0;
`;

export const BaseLoginMessageContainer = styled(Message)`
  text-align: ${({ header }) => (header ? 'center' : 'left')};
  font-size: ${({ header }) => (header ? svars.fontSizeXLarge : 'inherit')};
  line-height: ${({ header }) =>
    header ? svars.lineHeightSizeXLarge : 'inherit'};
  font-weight: ${({ header }) => (header ? svars.fontWeightLight : 'inherit')};
  color: ${({ error }) =>
    (error === true && svars.colorDanger) ||
    (error === false && svars.colorSuccess) ||
    svars.colorGrey};
  padding: ${svars.spaceMediumLarge} 0;
  min-height: 80px;
`;
BaseLoginMessageContainer.defaultProps = { error: null };

export const getRequestMessageStatus = (requestError) =>
  (requestError && { negative: true }) ||
  (requestError === false && { positive: true }) || { hidden: true };

export function LoginMessageContainer({ error, children }) {
  return (
    <BaseLoginMessageContainer {...getRequestMessageStatus(error)}>
      {children}
    </BaseLoginMessageContainer>
  );
}

LoginMessageContainer.propTypes = {
  error: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
LoginMessageContainer.defaultProps = { children: null };

function LeftMessage({ pathname }) {
  return (
    <LeftMessageContainer>
      {(pathname === '/login' && <Trans id="login.login-main-message" />) ||
        (pathname === '/renew-password' && (
          <Trans id="login.renew-password-main-message" />
        )) ||
        (pathname === '/update-password' && (
          <Trans id="login.update-password-main-message" />
        ))}
    </LeftMessageContainer>
  );
}

LeftMessage.propTypes = { pathname: PropTypes.string.isRequired };

function LeftFooter({ onToggleSupportModal, pathname }) {
  return (
    <div>
      {(pathname === '/login' && (
        <Trans>
          Un souci ? Contactez le
          <Link
            style={{ marginLeft: svars.spaceSmall }}
            base="true"
            inverted="true"
            onClick={onToggleSupportModal}
          >
            support
          </Link>
          .
        </Trans>
      )) ||
        ((pathname === '/renew-password' ||
          pathname === '/update-password') && (
          <Trans>
            Vous vous souvenez de votre mot de passe ? Retourner à la
            <RouterLinkV2
              style={{ marginLeft: svars.spaceSmall }}
              base="true"
              inverted="true"
              to="/login"
            >
              connexion
            </RouterLinkV2>
            .
          </Trans>
        ))}
    </div>
  );
}
LeftFooter.propTypes = {
  onToggleSupportModal: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
};

function LoginLayout({
  isAuthenticated,
  isBw,
  language,
  onSwitchLanguage,
  onToggleSupportModal,
}) {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate(state?.previousPathname || (isBw && '/admin') || '/facets');
    }
  }, [isAuthenticated]);
  return (
    <Container>
      <LeftColumn>
        <div />
        <LeftMessage pathname={pathname} />
        <LeftFooter
          onToggleSupportModal={onToggleSupportModal}
          pathname={pathname}
        />
      </LeftColumn>
      <RightColumn>
        <span />
        <span>
          <LogoImage style={{ height: '800px' }} src={logoWithFontImageSrc} />
          <FormContainer>
            <Outlet />
          </FormContainer>
        </span>
        <FooterContainer>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              target="_blank"
              href="https://www.better-world.io/user-terms"
              style={{ paddingRight: svars.spaceMedium }}
            >
              <Trans render={capitalizedTranslation} id="user-terms" />
            </Link>
            <Link
              target="_blank"
              href="https://www.better-world.io/privacynotice"
            >
              <Trans render={capitalizedTranslation} id="privacy" />
            </Link>
          </div>
          {!config.FORCE_CUSTOM_LOCALE ? (
            <InTextDropdown
              style={{ minWidth: '8rem', paddingTop: svars.spaceNormalLarge }}
              options={languages}
              value={language}
              onChange={onSwitchLanguage}
            />
          ) : null}
        </FooterContainer>
      </RightColumn>
    </Container>
  );
}

LoginLayout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isBw: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  onSwitchLanguage: PropTypes.func.isRequired,
  onToggleSupportModal: PropTypes.func.isRequired,
};

LoginLayout.defaultProps = {};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  isBw: state.user.user.isAdmin || state.user.user.isCsm,
  user: state.user.user,
  errorMessage: state.user.errorMessage,
  language: state.locale.language,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleSupportModal: () => dispatch(toggleSupportModal()),
  onSwitchLanguage: (e, { value }) => dispatch(switchLanguage(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);
