import { t } from '@lingui/macro';

const PERIOD_TYPES = {
  null: {
    type: null,
    header: t`all-historical-data`,
    help: t`facet-create.all-historical-data-help`,
    periods: [{ value: null, label: t`all-historical-data` }],
  },
  complete: {
    type: 'complete',
    header: t`last-complete-period`,
    help: t`facet-create.last-complete-period-help`,
    periods: [
      {
        value: '1M',
        label: t`1-month`,
        abbreviation: 'C1M',
      },
      {
        value: '3M',
        label: t`3-months`,
        abbreviation: 'C3M',
      },
      {
        value: '6M',
        label: t`6-months`,
        abbreviation: 'C6M',
      },
      {
        value: '12M',
        label: t`12-months`,
        abbreviation: 'C12M',
      },
    ],
  },
  rolling: {
    type: 'rolling',
    header: t`rolling-period`,
    help: t`facet-create.rolling-period-help`,
    periods: [
      {
        value: '1M',
        label: t`1-month`,
        abbreviation: 'R1M',
      },
      {
        value: '3M',
        label: t`3-months`,
        abbreviation: 'R3M',
      },
      {
        value: '6M',
        label: t`6-months`,
        abbreviation: 'R6M',
      },
      {
        value: '1Y',
        label: t`12-months`,
        abbreviation: 'R12M',
      },
    ],
  },
  current: {
    type: 'current',
    header: t`to-date-period`,
    help: t`facet-create.to-date-period-help`,
    periods: [
      {
        value: '3M',
        label: t`current-quarter`,
        abbreviation: 'QTD',
      },
      {
        value: '6M',
        label: t`current-semester`,
        abbreviation: 'HTD',
      },
      {
        value: '1Y',
        label: t`current-year`,
        abbreviation: 'YTD',
      },
    ],
  },

  custom: {
    type: 'custom',
    header: t`custom-period`,
    help: t`facet-create.custom-period-help`,
    periods: [
      {
        value: null,
        label: t`custom-period`,
      },
    ],
  },
};

export const getAbbreviation = (type, periodValue) => {
  const item = PERIOD_TYPES[type].periods.find(
    ({ value }) => periodValue === value
  );
  return (item && item.abbreviation) || null;
};

export default PERIOD_TYPES;
