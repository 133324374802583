import React, { useCallback, useState } from 'react';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import { ButtonSecondary } from 'components/ui/button';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import DragAndDropList from 'components/ui/layout/DragAndDropList';
import DeleteModal from 'components/ui/modal/DeleteModal';

import * as svars from 'assets/style/variables';

import { DraggableElementFactory } from './DraggableElement';
import FormItemManagementModal, {
  formElementTypeMapping,
} from './FormItemManagementModal';

const renderHeader = (item) => (
  <>
    {item.label}: <em>{formElementTypeMapping[item.form_type]}</em>
  </>
);

function FormItemsManagementList({
  onAddElement,
  onUpdateElement,
  onRemoveElement,
  onRestoreElement,
  onReorderFromDragAndDrop,
  onResetToDefault,
  addItemLabel,
  editItemLabel,
  restoreItemLabel,
  removeItemLabel,
  resetAllLabel,
  emptyListMessage,
  emptyListHeader,
  emptyDataIllustration,
  items,
  tagSetsOptions,
  mandatoryIsAllowed,
  mutableIsAllowed,
}) {
  const [managementModalIsOpen, setManagementModalIsOpen] = useState(false);
  const [confirmDeleteElement, setConfirmDeleteElement] = useState(null);
  const [selectedFormElement, setSelectedFormElement] = useState(null);

  const activateEdition = useCallback(async (element) => {
    setSelectedFormElement(element);
    setManagementModalIsOpen(true);
  }, []);
  const endModalSession = useCallback(() => {
    setManagementModalIsOpen(false);
    if (selectedFormElement) setSelectedFormElement(null);
  }, [selectedFormElement]);
  const activateCreation = useCallback(
    () => setManagementModalIsOpen(true),
    []
  );
  const closeDeleteModal = useCallback(() => setConfirmDeleteElement(null), []);
  const confirmDelete = useCallback(
    (element) => setConfirmDeleteElement(element),
    []
  );
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {onResetToDefault ? (
          <SecondaryTabButton disabled onClick={onResetToDefault}>
            {resetAllLabel}
          </SecondaryTabButton>
        ) : null}
        <SecondaryTabButton
          icon="add"
          labelPosition="right"
          onClick={activateCreation}
          style={{ marginLeft: svars.spaceMedium }}
          content={addItemLabel}
        />
      </div>
      <Divider />
      <DragAndDropList
        elements={items}
        onChange={onReorderFromDragAndDrop}
        renderElementSegment={DraggableElementFactory(
          activateEdition,
          onRestoreElement,
          confirmDelete,
          editItemLabel,
          restoreItemLabel,
          removeItemLabel,
          renderHeader
        )}
      />
      {items.length === 0 && (
        <EmptyDataPage
          headerText={emptyListHeader}
          illustrationUrl={emptyDataIllustration}
          actionComponent={
            <>
              <p>{emptyListMessage}</p>
              <ButtonSecondary onClick={activateCreation}>
                {addItemLabel}
              </ButtonSecondary>
            </>
          }
        />
      )}
      {managementModalIsOpen ? (
        <FormItemManagementModal
          addItemLabel={addItemLabel}
          editItemLabel={editItemLabel}
          formNames={items.map((element) => element.label)}
          create={!selectedFormElement}
          open={managementModalIsOpen}
          onClose={endModalSession}
          onAddElement={onAddElement}
          onUpdateElement={onUpdateElement}
          tagSetsOptions={tagSetsOptions}
          formElement={selectedFormElement}
          mandatoryIsAllowed={mandatoryIsAllowed}
          mutableIsAllowed={mutableIsAllowed}
        />
      ) : null}
      <DeleteModal
        open={!!confirmDeleteElement}
        onClose={closeDeleteModal}
        onDelete={() => onRemoveElement(confirmDeleteElement)}
        headerText={t`Are you sure you want to delete "${
          confirmDeleteElement?.label || ''
        }" ?`}
        contentText={t`form-item-deletion.confirm-modal-message`}
      />
    </>
  );
}

FormItemsManagementList.propTypes = {
  onUpdateElement: PropTypes.func.isRequired,
  onAddElement: PropTypes.func.isRequired,
  onRemoveElement: PropTypes.func.isRequired,
  onRestoreElement: PropTypes.func,
  onReorderFromDragAndDrop: PropTypes.func,
  onResetToDefault: PropTypes.func,
  addItemLabel: PropTypes.string.isRequired,
  editItemLabel: PropTypes.string.isRequired,
  restoreItemLabel: PropTypes.string.isRequired,
  removeItemLabel: PropTypes.string.isRequired,
  resetAllLabel: PropTypes.string.isRequired,
  emptyListHeader: PropTypes.string.isRequired,
  emptyListMessage: PropTypes.string.isRequired,
  emptyDataIllustration: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tagSetsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // Whether the form element can be flagged as mandatory
  mandatoryIsAllowed: PropTypes.bool.isRequired,
  // Whether the form element can be flagged as mutable
  mutableIsAllowed: PropTypes.bool.isRequired,
};

FormItemsManagementList.defaultProps = {
  onRestoreElement: null,
  onResetToDefault: null,
  onReorderFromDragAndDrop: null,
  emptyDataIllustration: undefined,
};

export default FormItemsManagementList;
