import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { toggleSupportModal } from 'actions/ui';
import { api } from 'actions/utils';

import Link from 'components/ui/Link';
import { AnalyticsAwareButton, ButtonAccent } from 'components/ui/button';
import { TextInput } from 'components/ui/inputs/TextInput';
import { ButtonLineLayout } from 'components/ui/layout/Page';

import commonPropTypes from 'utils/commonPropTypes';
import 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import {
  LoginHeaderMessageContainer,
  LoginMessageContainer,
} from './LoginLayout';

function RequestMessage({ error, onToggleSupportModal }) {
  return (
    <LoginMessageContainer error={error}>
      {(error && (
        <Trans>
          Nous ne pouvons trouver votre compte ou nous n&apos;avons pas votre
          email, veuillez{' '}
          <Link base="true" onClick={onToggleSupportModal}>
            contacter le support.
          </Link>
        </Trans>
      )) ||
        (error === false && (
          <div>
            <div>
              <Trans id="renew-password.an-email-has-been-sent-check-mailbox" />
            </div>
            <div>
              <Trans id="renew-password.check-your-spams" />
            </div>
          </div>
        )) ||
        null}
    </LoginMessageContainer>
  );
}

RequestMessage.propTypes = {
  onToggleSupportModal: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

function RenewPasswordPage({ onToggleSupportModal, language }) {
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState(searchParams.get('user') || '');
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(undefined);
  const [tokenSent, setTokenSent] = useState(false);

  const onChangeUsername = useCallback(
    (event, data) => setUsername(data.value),
    []
  );
  const requestPasswordRenewal = useCallback(
    async (newUsername) => {
      setLoading(true);
      setRequestError(null);
      let newRequestError = false;
      let newTokenSent = newUsername;
      try {
        await api.post('/user/renew-password', {
          username: newUsername,
          locale: language,
        });
      } catch (error) {
        newRequestError = true;
        newTokenSent = false;
      }
      setRequestError(newRequestError);
      setTokenSent(newTokenSent);
      setLoading(false);
    },
    [username, setLoading, setRequestError, setTokenSent]
  );

  return (
    <>
      <LoginHeaderMessageContainer>
        <Trans id="renew-password.enter-email-related-to-account" />
      </LoginHeaderMessageContainer>
      <Form style={{ width: '100%' }}>
        <Form.Field>
          <TextInput
            id="Username"
            icon="user"
            iconPosition="left"
            onChange={onChangeUsername}
            placeholder={t`email-or-username`}
            value={username}
          />
        </Form.Field>
        <ButtonLineLayout
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <AnalyticsAwareButton
            gaCategory="Customer"
            gaAction="Request password renewal"
            gaLabel={username}
            inputComponent={ButtonAccent}
            type="submit"
            onClick={() => !loading && requestPasswordRenewal(username)}
            disabled={!username || tokenSent === username}
            loading={loading}
          >
            <Trans render={capitalizedTranslation} id="send-reset-link" />
          </AnalyticsAwareButton>
        </ButtonLineLayout>
        <RequestMessage
          onToggleSupportModal={onToggleSupportModal}
          error={requestError}
        />
      </Form>
    </>
  );
}

RenewPasswordPage.propTypes = {
  language: commonPropTypes.language.isRequired,
  onToggleSupportModal: PropTypes.func.isRequired,
};

RenewPasswordPage.defaultProps = {};

export default connect(
  (state) => ({
    language: state.locale.language,
  }),
  (dispatch) => ({
    onToggleSupportModal: () => dispatch(toggleSupportModal()),
  })
)(RenewPasswordPage);
