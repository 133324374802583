import React from 'react';

import PropTypes from 'prop-types';

import BaseTimeSeriesVisualization from 'components/ui/visualization/BaseTimeSeriesVisualization';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';

import commonPropTypes from 'utils/commonPropTypes';

import * as styleVariables from 'assets/style/variables';

const SELECTED_SENTIMENT_SERIES = 'average_sentiment';

export function ConceptTimeSeriesVisualizations({ data, loading, id }) {
  if (loading) {
    return <LoadingDataVisualization height={styleVariables.chartHeight} />;
  }
  const gradientName = `colorSentiment-${id}`;
  const sentiments = data.map((it) => it[SELECTED_SENTIMENT_SERIES]);
  return (
    <BaseTimeSeriesVisualization
      data={data}
      sentimentGradientName={gradientName}
      sentiments={sentiments}
      tooltipFieldKeys={[
        'date',
        'cumulated',
        'n_polarities',
        SELECTED_SENTIMENT_SERIES,
      ]}
      margins={{
        top: 5,
        bottom: 0,
      }}
      childrenAttributes={[
        { key: 'cumulated', y_id: 1 },
        { key: 'n_negative', y_id: 2 },
        { key: 'n_neutral', y_id: 2 },
        { key: 'n_positive', y_id: 2 },
        { key: SELECTED_SENTIMENT_SERIES, y_id: 3 },
      ]}
      yAxisProps={{
        1: { hide: true },
        2: { hide: true },
        3: {},
      }}
    />
  );
}

ConceptTimeSeriesVisualizations.propTypes = {
  data: commonPropTypes.timeSeries,
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

ConceptTimeSeriesVisualizations.defaultProps = {
  loading: false,
  data: undefined,
};

export default ConceptTimeSeriesVisualizations;
