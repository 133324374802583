import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const ClickableSpan = styled.span`
  display: flex;
  max-width: 100%;
  padding: ${svars.spaceNormal} ${svars.spaceNormal} ${svars.spaceNormal} 0;
  white-space: nowrap;
  color: ${(props) => (props.disabled ? 'inherit' : svars.colorPrimary)};
  cursor: pointer;
  &:hover {
    color: ${svars.accentColor};
  }
`;

const EllipsableTextContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

function SortableHeader({ label, onSort, disabled, ascending }) {
  const iconName = !disabled && ascending ? 'caret up' : 'caret down';
  return (
    <ClickableSpan disabled={disabled} onClick={onSort}>
      <EllipsableTextContainer>{label}</EllipsableTextContainer>
      <Icon
        style={{
          marginLeft: svars.spaceSmall,
          opacity: disabled ? 0.35 : 1,
        }}
        name={iconName}
      />
    </ClickableSpan>
  );
}

SortableHeader.propTypes = {
  disabled: PropTypes.bool,
  ascending: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

SortableHeader.defaultProps = { disabled: false, ascending: true };

export default SortableHeader;
