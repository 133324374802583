import React from 'react';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RowActions from 'components/ui/ManagementList/RowActions';
import RowMenu from 'components/ui/ManagementList/RowMenu';
import { FlexCell } from 'components/ui/ManagementList/header';

const FlexRow = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ManagementListRowFactory = (onDelete, rowActions, allowDelete = true) => {
  function CampaignRow({ fields, item }) {
    const menuItems = [
      {
        content: t`delete`,
        icon: 'delete',
        iconColor: 'red',
        onClick: () => onDelete(item),
        disabled: !allowDelete,
      },
    ];
    return (
      <FlexRow>
        {fields.map(
          ({ Cell, accessor, header, getCellProps, width, centered }) => {
            const value = accessor(item);
            return (
              <FlexCell
                width={width}
                verticalAlign="middle"
                key={header}
                centered={centered}
              >
                {Cell ? <Cell value={value} {...getCellProps(item)} /> : value}
              </FlexCell>
            );
          }
        )}
        <RowActions actions={rowActions} item={item} />
        <FlexCell width={10} alignRight>
          <RowMenu items={menuItems} />
        </FlexCell>
      </FlexRow>
    );
  }
  CampaignRow.propTypes = {
    item: PropTypes.shape({
      name: PropTypes.string,
      app_route: PropTypes.string,
      id: PropTypes.string,
      create_date: PropTypes.string,
    }).isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };
  return CampaignRow;
};

export default ManagementListRowFactory;
