import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'semantic-ui-react';

import SentimentVolumeScatterChart from 'components/customer/visualization/scatter-chart/SentimentVolumeScatterChart';
import { LightHeader } from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentAccent,
} from 'components/ui/button';
import { exportElementAsImage } from 'components/ui/button/export-as/ExportAsImage';
import { compileExportFilename } from 'components/ui/button/export-as/utils';
import { AnalyticsAwareHoverableIconButton } from 'components/ui/icon/HoverableIcon';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';
import CheckboxList from 'components/ui/inputs/checkbox-list';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';

import { logEvent } from 'utils/analytics';
import commonPropTypes from 'utils/commonPropTypes';
import { useDoubleClick } from 'utils/hooks';

import * as svars from 'assets/style/variables';

const getToggleShapeDisplayButton = (onClick) => {
  function Button({ item }) {
    return (
      <AnalyticsAwareHoverableIconButton
        gaCategory="Scatter export modal"
        gaAction="Hide/show dot (from list)"
        name={item?.displayed ? 'eye' : 'eye slash'}
        warning={`${!item?.displayed}`}
        onClick={(event) => {
          event.stopPropagation();
          onClick(item);
        }}
      />
    );
  }
  Button.propTypes = {
    item: PropTypes.shape({ displayed: PropTypes.bool }).isRequired,
  };
  return Button;
};

function ScatterChartWithExportModal({
  shape,
  data,
  modalIsOpen,
  onCloseModal,
  chartId,
  exportName,
  viewFacet,
  loading,
  ...props
}) {
  if (loading) return <LoadingDataVisualization height={props.height} />;
  const [exportData, setExportData] = useState(null);
  useEffect(() => {
    // Reset export data to force reset of scatter labels when data changes
    if (exportData) setExportData(null);
  }, [data, modalIsOpen]);
  const [initialScatterLabels, setInitialScatterLabels] = useState([]);
  const [lastExportName, setExportName] = useState('');

  // Reset filename upon opening the modal
  useEffect(() => {
    setExportName(
      compileExportFilename((viewFacet && viewFacet.name) || null, exportName)
    );
  }, [modalIsOpen]);
  const [exportLoading, setExportLoading] = useState(false);
  const generateScatterLabels = useCallback(
    (newScatterLabels) => {
      const checkedItemKeys = newScatterLabels
        .map((labelItem) => (labelItem.checked && labelItem.key) || null)
        .filter((item) => !!item);
      const initialExportData = newScatterLabels.map((exportDataItem) => ({
        ...exportDataItem,
        checked: checkedItemKeys.includes(exportDataItem.key),
        displayed: true,
      }));
      setExportData(initialExportData);
      setInitialScatterLabels(initialExportData);

      setExportData(
        newScatterLabels.map((exportDataItem) => ({
          ...exportDataItem,
          checked: checkedItemKeys.includes(exportDataItem.key),
          displayed:
            exportDataItem.displayed === undefined
              ? true
              : exportDataItem.displayed,
        }))
      );
    },
    [initialScatterLabels, setExportData]
  );
  const resetScatterLabels = useCallback(
    () => setExportData(initialScatterLabels),
    [initialScatterLabels]
  );
  const unselectAllScatterLabels = useCallback(
    () =>
      setExportData(
        exportData.map((exportDataItem) => ({
          ...exportDataItem,
          checked: false,
        }))
      ),
    [exportData]
  );
  const toggleLabel = useCallback(
    (pointItem) => {
      setExportData(
        exportData.map((item) =>
          item.key === pointItem.key
            ? {
                ...item,
                checked: !item.checked,
              }
            : item
        )
      );
    },
    [exportData]
  );
  const toggleShape = useCallback(
    (shapeItem) => {
      setExportData(
        exportData.map((item) => {
          if (shapeItem.label === item.label) {
            return { ...item, displayed: !item.displayed };
          }
          return item;
        })
      );
    },
    [exportData, setExportData]
  );
  const exportChartId = `export-${chartId}`;
  const onExportChart = useCallback(() => {
    if (!exportLoading) {
      setExportLoading(true);
      exportElementAsImage(
        document,
        exportChartId,
        lastExportName,
        () => {
          setExportLoading(false);
          logEvent({
            category: 'Customer - Analyze',
            action: 'Export image',
            label: 'exportFileName',
          });
          // Closing modal afterward
          onCloseModal();
        },
        // Configure offset / scrollHeight specifically for modal export
        document.documentElement.offsetHeight,
        0
      );
    }
  }, [exportChartId, lastExportName, exportLoading, onCloseModal]);
  const hybridClick = useDoubleClick(
    (event, item) => toggleShape(item),
    (event, item) => {
      toggleLabel(item);
    }
  );
  const modalChartExtraProps = {};
  if (shape) {
    modalChartExtraProps.shape = ({ displayed, ...item }) => {
      if (displayed === false) return null;
      const rendered = shape({
        ...item,
        displayed,
        onClick: hybridClick,
      });
      return rendered;
    };
  } else {
    modalChartExtraProps.onShapeClick = hybridClick;
  }
  return (
    <>
      <SentimentVolumeScatterChart
        chartId={chartId}
        data={data}
        shape={shape}
        {...props}
      />
      {modalIsOpen && (
        <Modal
          icon="search"
          closeIcon
          onClose={onCloseModal}
          open={modalIsOpen}
        >
          <Modal.Header>
            <Trans id="export-a-chart" />
          </Modal.Header>
          <Modal.Content
            style={{
              minHeight: '63vh',
              padding: `0 ${svars.spaceMediumLarge}`,
              userSelect: 'none',
            }}
          >
            <Segment
              style={{
                height: '50vh',
                marginTop: svars.spaceMedium,
                display: 'inline-flex',
              }}
            >
              <span
                style={{
                  marginRight: svars.spaceMedium,
                  width: '75%',
                  margin: 'auto',
                }}
              >
                <SentimentVolumeScatterChart
                  {...props}
                  {...modalChartExtraProps}
                  data={exportData || data}
                  chartId={exportChartId}
                  scatterLabels={exportData || []}
                  setScatterLabels={generateScatterLabels}
                  isAnimationActive={false}
                  reducedTooltip
                />
                <p
                  style={{
                    paddingTop: svars.spaceNormal,
                    color: svars.fontColorLighter,
                    fontWeight: svars.fontWeightLight,
                    fontStyle: 'italic',
                    fontSize: svars.fontSizeSmaller,
                  }}
                >
                  <Icon name="circle info" />
                  <Trans id="drag-and-drop-label-to-position" />
                  <br />
                  <Icon name="circle info" />
                  <Trans id="click-on-point-to-remove-or-hide-label" />
                </p>
              </span>
              <span
                style={{
                  marginLeft: svars.spaceNormal,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <p>
                  <Trans id="scatter-chart-export-modal.select-labels-to-display" />
                </p>
                <CheckboxList
                  items={exportData || []}
                  loading={exportData === null}
                  onSelectItem={toggleLabel}
                  onUnselectItem={toggleLabel}
                  onResetItems={resetScatterLabels}
                  onUnselectAllItems={unselectAllScatterLabels}
                  placeholder={t`search`}
                  selectedItems={
                    exportData
                      ? exportData
                          .filter(({ checked }) => checked)
                          .map(({ id }) => id)
                      : []
                  }
                  searchable
                  disabled={!exportData}
                  noDataMessage={t`no-existing-group`}
                  extraAction={getToggleShapeDisplayButton(toggleShape)}
                />
              </span>
            </Segment>
            <Segment
              style={{
                marginTop: svars.spaceMedium,
                marginBottom: svars.spaceMedium,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <LightHeader>
                <Trans id="filename" /> :
              </LightHeader>
              <LimitedTextInput
                style={{
                  width: '100%',
                  paddingRight: svars.spaceMedium,
                  fontWeight: svars.fontWeightSemiBold,
                  fontSize: svars.fontSizeBase,
                }}
                value={lastExportName}
                onChange={(e, { value }) => setExportName(value)}
                maxCharacters={200}
              />
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <AnalyticsAwareButton
              gaCategory="Scatter export modal"
              gaAction="Export"
              gaLabel={`facet=${viewFacet.id}`}
              inputComponent={ButtonTransparentAccent}
              onClick={onCloseModal}
            >
              <Trans id="cancel" />
            </AnalyticsAwareButton>
            <AnalyticsAwareButton
              gaCategory="Scatter export modal"
              gaAction="Export - cancel"
              inputComponent={ButtonAccent}
              loading={exportLoading}
              onClick={onExportChart}
            >
              <Trans id="export" />
            </AnalyticsAwareButton>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
}

ScatterChartWithExportModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  chartId: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  exportName: PropTypes.string.isRequired,
  viewFacet: commonPropTypes.viewFacet,
  data: PropTypes.arrayOf(commonPropTypes.kpiPropTypes),
  shape: PropTypes.func,
  height: PropTypes.number,
  loading: PropTypes.bool,
};
ScatterChartWithExportModal.defaultProps = {
  viewFacet: undefined,
  data: null,
  shape: null,
  loading: false,
  height: svars.chartHeight,
};

export default connect((state) => ({ viewFacet: state.view.viewFacet }))(
  ScatterChartWithExportModal
);
