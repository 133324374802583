import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const ErrorMessageContainer = styled.div`
  padding-top: ${svars.spaceNormal};
  color: ${svars.colorDanger};
  min-height: ${svars.fontSizeBase};
  transition: ${svars.transitionBase};
`;

export function ErrorMessage({ message, show }) {
  return show ? (
    <ErrorMessageContainer>
      <Icon name="exclamation circle" />
      {message}
    </ErrorMessageContainer>
  ) : (
    <ErrorMessageContainer />
  );
}

ErrorMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
