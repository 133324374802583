import React from 'react';

import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';
import styled from 'styled-components';

import { LightHeader } from 'components/ui/Header';
import HelpTooltip from 'components/ui/HelpTooltip';
import { LightCheckbox } from 'components/ui/inputs/Checkbox';

import * as svars from 'assets/style/variables';

const StyledAccordion = styled(Accordion)`
  &&& {
    & div.title:first-letter {
      text-transform: uppercase;
    }
  }
`;

StyledAccordion.Title = Accordion.Title;
StyledAccordion.Content = Accordion.Content;

function AccordionToggleTitle({ title, help, active, onToggle }) {
  return (
    <Accordion.Title
      active={active}
      index={0}
      onClick={onToggle}
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <LightHeader nomargin="true">
        {title}
        {help && <HelpTooltip help={help} iconSize={svars.fontSizeLarge} />}
      </LightHeader>
      <LightCheckbox toggle checked={active} />
    </Accordion.Title>
  );
}

AccordionToggleTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  help: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  active: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

AccordionToggleTitle.defaultProps = { help: null, active: false };

function AccordionItem({ content, active, ...titleProps }) {
  return (
    <>
      <AccordionToggleTitle {...titleProps} active={active} />
      <Accordion.Content active={active}>{content}</Accordion.Content>
    </>
  );
}

AccordionItem.propTypes = {
  ...AccordionToggleTitle.propTypes,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
AccordionItem.defaultProps = { ...AccordionToggleTitle.defaultProps };

export { AccordionToggleTitle, AccordionItem };
export default StyledAccordion;
