import styled, { keyframes } from 'styled-components';

import * as svars from 'assets/style/variables';

const backgroundGradient = keyframes`
  0% {
    background-color: ${svars.accentColorLighter};
  }
  60% {
    background-color: ${svars.accentColorClear};
  }
  100% {
    background-color: ${svars.accentColorLighter};
  }
`;

const BeatingLoader = styled.div`
  ${({ absolute }) =>
    absolute
      ? 'position: absolute; width: 100%; height: -webkit-fill-available;'
      : 'height: 100%;'}
  opacity: 0.5;
  animation: ${backgroundGradient} 1.5s;
  backface-visibility: hidden;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background-image: radial-gradient(
    ellipse closest-side,
    transparent,
    ${({ white }) => (white ? svars.colorWhite : svars.backgroundColorBase)}
  );
`;

export default BeatingLoader;
