// import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const StyledSegment = styled(Segment)`
  &&& {
    font-family: ${svars.fontFamilySansSerif};
    border-radius: ${svars.borderRadius};
    border-color: ${svars.colorLightGrey};
    box-shadow: ${({ flat }) => (flat ? 'none' : svars.baseBoxShadow)};
  }
`;

export const NormalSpacePaddedSegment = styled(StyledSegment)`
  &&&& {
    padding: ${svars.spaceNormal};
    margin: ${svars.spaceNormal} 0;
  }
`;
export const MediumPaddedSegment = styled(StyledSegment)`
  &&&& {
    padding: ${svars.spaceMedium};
    margin: ${svars.spaceMedium} 0;
  }
`;

export default StyledSegment;
