import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';

import { HoverableIconButton } from 'components/ui/icon/HoverableIcon';

import * as svars from 'assets/style/variables';

function RowMenu({ items, disabled }) {
  return (
    <Dropdown
      disabled={disabled}
      direction="left"
      icon={
        <HoverableIconButton
          style={{ fontSize: svars.fontSizeMedium }}
          name="ellipsis vertical"
        />
      }
    >
      <Dropdown.Menu>
        {items.map(
          ({
            to,
            content,
            iconColor,
            icon,
            onClick,
            disabled: valueDisabled,
          }) => (
            <Dropdown.Item
              key={`rmddmi-${content}`}
              as={RouterLink}
              to={to}
              onClick={onClick}
              content={content}
              icon={<Icon color={iconColor} name={icon} />}
              disabled={valueDisabled}
            />
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

RowMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      content: PropTypes.string,
      iconColor: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  disabled: PropTypes.bool,
};

RowMenu.defaultProps = { disabled: false, items: null };

export default RowMenu;
