import React from 'react';
import useDimensions from 'react-cool-dimensions';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';

import * as svars from 'assets/style/variables';

import Pointer, { DottedPointer } from './PointerShape';

const Title = styled.div`
  padding-top: ${svars.spaceMedium};
  width: ${({ width }) => width}px;
  text-align: center;
`;

const TickContainer = styled.div`
  height: 10px;
  width: 0px;
  margin-top: -${({ axisHeight }) => axisHeight + 2}px;
  margin-right: 3px;
  border-left: 1px solid
    ${({ danger }) => (danger ? svars.colorDanger : svars.fontColorBase)};

  & span {
    position: relative;
    white-space: pre;
    left: ${({ isFirstTick }) => (isFirstTick ? '-23px' : '23px')};
    float: ${({ isFirstTick }) => (isFirstTick ? 'left' : 'right')};
    direction: ${({ isFirstTick }) => (isFirstTick ? 'ltr' : 'rtl')};
    top: -${({ axisHeight }) => axisHeight + 10}px;
  }
`;

function Tick({ axisHeight, locked, legend, isFirstTick, danger }) {
  return (
    <TickContainer
      axisHeight={axisHeight}
      isFirstTick={isFirstTick}
      danger={danger}
    >
      <span>
        <HelpTooltip
          compact
          trigger={
            <div style={{ display: 'inline' }}>
              <Icon
                name={locked ? 'lock' : 'refresh'}
                style={{ margin: `0 ${svars.spaceNormal}` }}
                size="small"
              />
            </div>
          }
          position="top center"
          help={
            locked ? (
              <Trans id="this-date-will-not-be-updated" />
            ) : (
              <Trans id="this-date-will-be-updated" />
            )
          }
        />

        {legend}
      </span>
    </TickContainer>
  );
}

Tick.propTypes = {
  axisHeight: PropTypes.number.isRequired,
  locked: PropTypes.bool,
  legend: PropTypes.string.isRequired,
  isFirstTick: PropTypes.bool,
  danger: PropTypes.bool,
};
Tick.defaultProps = { locked: false, isFirstTick: false, danger: false };

export function BasePointerAxis({
  title,
  width,
  firstTickLegend,
  secondTickLegend,
  isLastPointerDotted,
  isFirstPointerLight,
  middlePointerWidthRatio,
  isFirstTickLocked,
  isSecondTickLocked,
  danger,
}) {
  const height = 8;
  const LastPointer = isLastPointerDotted ? DottedPointer : Pointer;
  const finalRatio = Math.min(Math.max(0.2, middlePointerWidthRatio), 0.8);
  const lastPointerWidth = width / 6;
  let middlePointerWidth = Math.max(finalRatio * width, width / 3);
  let firstPointerWidth = width - lastPointerWidth - middlePointerWidth;
  if (!firstTickLegend) {
    middlePointerWidth += firstPointerWidth;
    firstPointerWidth = 0;
  }
  return (
    <div style={{ color: danger ? svars.colorDanger : 'inherit' }}>
      <div
        style={{ display: 'flex', width, height, marginTop: svars.spaceLarge }}
      >
        <Pointer
          height={height}
          light={isFirstPointerLight}
          width={firstPointerWidth}
          danger={danger}
        />
        {firstTickLegend && (
          <Tick
            axisHeight={height}
            locked={isFirstTickLocked}
            legend={firstTickLegend}
            danger={danger}
            isFirstTick
          />
        )}
        <Pointer
          style={{ minWidth: '155px' }}
          height={height}
          width={middlePointerWidth}
          danger={danger}
        />
        <Tick
          axisHeight={height}
          locked={isSecondTickLocked}
          legend={secondTickLegend}
          danger={danger}
        />
        <LastPointer
          light={!isLastPointerDotted}
          height={height}
          width={lastPointerWidth}
          danger={danger}
        />
      </div>
      <Title width={width}>{title}</Title>
    </div>
  );
}

BasePointerAxis.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  firstTickLegend: PropTypes.string,
  secondTickLegend: PropTypes.string.isRequired,
  isLastPointerDotted: PropTypes.bool,
  isFirstPointerLight: PropTypes.bool,
  middlePointerWidthRatio: PropTypes.number,
  isFirstTickLocked: PropTypes.bool,
  isSecondTickLocked: PropTypes.bool,
  // Whether to display in danger colors
  danger: PropTypes.bool,
};

BasePointerAxis.defaultProps = {
  firstTickLegend: null,
  isLastPointerDotted: false,
  isFirstPointerLight: false,
  middlePointerWidthRatio: 0.5,
  isFirstTickLocked: false,
  isSecondTickLocked: false,
  danger: false,
};

function ResponsivePointerAxis({ style, ...props }) {
  const { observe, width } = useDimensions({});

  return (
    <div ref={observe} style={{ width: '100%', ...style }}>
      <BasePointerAxis {...props} width={width} />
    </div>
  );
}

// eslint-disable-next-line react/forbid-prop-types
ResponsivePointerAxis.propTypes = { style: PropTypes.object };
ResponsivePointerAxis.defaultProps = { style: {} };

export default ResponsivePointerAxis;
